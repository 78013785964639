<template>
  <div>
    <section class="container">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb
            class="breadcumb--item-color__scoped conteudos_tematicos_section_bc-color"
          >
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <v-col class="d-flex justify-center align-center">
          <img
            width="50px"
            src="../../assets/conteudos_ser_atingido_title_diamond.png"
            alt=""
          />
          <h3 class="ml-3" style="color: #E06919">
            Não Reconhecidos
          </h3>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="8"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_ser_atingido_nao_reconhecidos_01.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card
            borderColor="#426E51"
            title="Os não reconhecidos como atingidos"
            titleColor="#426E51"
            title_alignment="center"
          >
            <template v-slot:conteudo>
              <p>
                Como alternativa à judicialização de demandas indenizatórias
                pelas pessoas atingidas pelo rompimento da barragem de Fundão,
                em Mariana, foi criada a Fase de Negociação Extrajudicial (FNE)
                no Termo de Acordo e Ajustamento de Conduta (TTAC), assinado em
                02 de outubro de 2018 no âmbito da Ação Civil Pública nº
                0400.15.004335-6. O mesmo acordo definiu que, para quem optou
                por participar do processo de cadastramento promovido pela
                Cáritas MG, o produto final, denominado
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_sistematizacao_dossie' }"
                  >dossiê</router-link
                >, seria o instrumento para reportar à Fundação Renova os danos
                sofridos em razão do rompimento, e esta deveria considerá-lo
                para fins de reconhecimento e fixação das indenizações.
              </p>
              <p>
                Nos termos do TTAC, os núcleos familiares atingidos, ao
                receberem seu dossiê individualizado, são informados da
                possibilidade de encaminhar este documento à Fundação Renova e
                que, a partir do seu recebimento, a entidade
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_descumprimento_prazos' }"
                  >tem o prazo de três meses</router-link
                >
                para apresentar uma proposta de indenização ao núcleo familiar,
                considerando os registros das perdas e danos constantes no seu
                dossiê.
              </p>
              <p>
                Ao receber um dossiê, então, a Fundação Renova deveria
                possibilitar ao núcleo familiar atingido negociar
                extrajudicialmente sua indenização, através da FNE, conforme
                previsto no TTAC celebrado entre as empresas responsáveis pelo
                rompimento e o Ministério Público de Minas Gerais.
              </p>
              <p>
                No entanto, de forma recorrente, a Fundação Renova nega a
                diversas pessoas atingidas,
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_desconsideracao_cadastro' }"
                  >tendo recebido seu dossiê</router-link
                >
                ou não, a oportunidade de participar das tratativas da FNE. Como
                prática habitual, apenas uma carta é enviada ao endereço do
                núcleo familiar atingido, informando-o não ser elegível para
                participação na FNE, de acordo com as diretrizes de indenização
                da própria Fundação Renova.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Essa prática de envio de cartas de inelegibilidade retira a
                possibilidade de que as pessoas atingidas destinatárias tenham
                acesso direto aos advogados e analistas da Fundação Renova para
                o esclarecimento de dúvidas, para que possam questionar o
                conteúdo da carta, excluindo, por fim, qualquer possibilidade de
                negociação extrajudicial.
              </p>
              <p>
                Inicialmente, as pessoas atingidas eram comunicadas sobre a
                inelegibilidade por advogados e analistas da Fundação Renova, e
                era possibilitado a elas o seu ingresso na FNE. Houve casos
                acompanhados pela Assessoria Jurídica da Cáritas MG, inclusive,
                em que foi possível reverter a inelegibilidade com posterior
                apresentação de proposta indenizatória pela Fundação Renova ao
                núcleo familiar atingido. No entanto, por razões desconhecidas,
                em determinado momento, a entidade passou a adotar apenas o
                envio de correspondências para comunicar a inelegibilidade.
              </p>
              <p>
                A maioria dessas correspondências fundamentam a inelegibilidade
                no fato de que as perdas e danos declarados no dossiê não
                decorreram “diretamente” do rompimento da barragem de Fundão.
                Abaixo, leia-se a frase padrão encontrada ao final das cartas de
                inelegibilidade encaminhadas a várias pessoas atingidas:
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="8"
          class="d-flex justify-center align-center border__image mb-md-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            “Deste modo, considerando os fatos acima,
            <strong>inexiste dano diretamente decorrente</strong> do rompimento
            da barragem de Fundão a ser indenizado na FNE.”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                A categorização como “indireto” de danos que têm relação íntima
                com o rompimento da barragem se dá em razão do conceito
                extremamente reduzido de atingido adotado pela Fundação Renova.
                Esta, ao considerar como elegíveis à indenização somente aqueles
                que tiveram suas propriedades atingidas pelos rejeitos de lama,
                desvirtua por completo o instituto jurídico da responsabilidade
                civil, na tentativa de se esquivar do dever de indenizar
                milhares de pessoas atingidas pelo desastre–crime.
              </p>
              <p>
                Nesse sentido, a perda de vínculos afetivos, culturais e
                comunitários, de memórias, dentre outros danos materiais ou
                imateriais são completamente ignorados pela Fundação Renova para
                fins de considerar uma pessoa atingida como elegível a ser
                indenizada na FNE. Há, inclusive, casos de pessoas atingidas que
                ingressaram na FNE juntamente com o seu núcleo familiar, mas que
                apenas parte da família foi reconhecida como “diretamente”
                atingida pelo rompimento.
              </p>
              <p>
                Percebe-se que, agindo dessa forma, a Fundação Renova
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_desconsideracao_cadastro' }"
                  >despreza as autodeclarações das pessoas
                  atingidas</router-link
                >. Não raras vezes, nas cartas de inelegibilidade consta o
                argumento de que não foi constatada verossimilhança nas
                declarações constantes no dossiê.
              </p>
              <p>
                Em algumas ocasiões, inclusive, a Fundação Renova baseia-se em
                declarações prestadas pelas pessoas atingidas nos cadastros
                emergenciais para considerá-las inelegíveis. Sendo que à época
                desses cadastros não era possível sequer dimensionar a extensão
                dos danos decorrentes do rompimento da barragem de Fundão.
                Diante disto, resta como única possibilidade às pessoas
                atingidas não reconhecidas como tal pela Fundação Renova
                recorrer ao caminho judicial, por vezes lento e tortuoso. Assim,
                expande-se ainda mais o rol de danos sofridos por estas famílias
                desde o rompimento da barragem.
              </p>
              <p>
                Convida-se à leitura do
                <a
                  target="_blank"
                  style="text-decoration: none"
                  href="https://www.google.com/url?q=http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf&sa=D&source=docs&ust=1653146093495057&usg=AOvVaw0U-VdAtCGHL5Bp8DqQcJmC"
                  class="pink--text"
                  >Parecer Técnico Jurídico</a
                >
                produzido pela equipe de Assessoria Jurídica da Cáritas MG, em
                que foram relatadas, com mais detalhes, as práticas e violações
                da Fundação Renova, no âmbito da FNE, perpetradas a fim de negar
                a
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_luta_reparacao' }"
                  >reparação integral</router-link
                >
                dos danos decorrentes do rompimento da barragem de
                Fundão<sup>1</sup>.
              </p>
              <p style="text-align: center">
                <strong
                  >Autoria: Equipe de Assessoria Jurídica da ATI de
                  Mariana</strong
                >
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup>
              <a
                target="_blank"
                style="text-decoration: none"
                href="http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf"
                class="pink--text"
                >http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf</a
              >
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center pa-4 section__mb">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_ser_atingido_tempos' }"
            color="#EDBD43"
          >
            <strong>Conhecer "Tempos"</strong>
          </v-btn>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb.vue";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos / ",
          href: "conteudos_tematicos",
        },

        {
          text: "Ser Atingido /",
          href: "conteudos_tematicos_ser_atingido",
        },

        {
          text: "Não reconhecidos",
          href: "conteudos_tematicos_ser_atingido_nao_reconhecidos",
        },
      ],
    };
  },
};
</script>

<style scoped>
.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .border__image {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("./assets/Vector-4.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 0 2em 2em 2em;
}

.section__mb {
  padding-bottom: 3em;
}
</style>
